import Gift8 from "./assets/image/SelectorImage/gift_8.png";
import Gift7 from "./assets/image/SelectorImage/gift_7.png";
import Gift6 from "./assets/image/SelectorImage/gift_6.png";
import Gift5 from "./assets/image/SelectorImage/gift_5.png";
import Gift4 from "./assets/image/SelectorImage/gift_4.png";
import Gift3 from "./assets/image/SelectorImage/gift_3.png";
import Gift2 from "./assets/image/SelectorImage/gift_2.png";
import Gift1 from "./assets/image/SelectorImage/gift_1.png";

export const imageShowData = [Gift1, Gift2, Gift3, Gift4, Gift5, Gift6, Gift7, Gift8];
